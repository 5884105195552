<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"

            left-arrow
            @click-left="$router.go(-1)"
    >
    </van-nav-bar>

    <div class="text1">{{$t('login.text3[0]')}}</div>
    <div class="text2">{{$t('login.text3[1]')}}</div>

    <div class="ScrollBox">
      <van-form @submit="onSubmit">
        <van-field
                v-model.trim="postData.username"
                :placeholder="$t('resetpwd[1]')"
                clearable
                autocomplete="off"
                :border="false"
                class="input"
                :type="showPass1 ? 'text' : 'password'"
        />
        <van-field
                type="password"
                autocomplete="off"
                style="height: 0; width: 0; padding: 0; position: absolute"
        />
        <van-field
                v-model.trim="postData.smscode"
                autocomplete="off"
                type="digit"
                class="input"
                :placeholder="$t('resetpwd[2]')"
                clearable
                :border="false"
        >
          <template #button>
            <van-button style="margin-right:-30px"
                        size="mini"
                        color="#59D4E4"
                        round
                        native-type="button"
                        @click="getSmsCode"
                        :loading="isSendCode"
                        :loading-text="$t('resetpwd[3]')"
            >{{ $t('resetpwd[3]') }}</van-button
            >
          </template>
        </van-field>
        <van-field
                type="password"
                v-model.trim="postData.password"
                :placeholder="$t('resetpwd[4]')"
                clearable
                autocomplete="off"
                :border="false"
                class="input"
                :type="showPass1 ? 'text' : 'password'"
                :right-icon="showPass1 ? 'eye' : 'closed-eye'"
                @click-right-icon="showPass1 = !showPass1"
        />
        <Loading />
        <div style="padding: 33px 16px">
          <p class="btn" @click="onSubmit()">{{ $t('resetpwd[5]') }}</p>
        </div>
      </van-form>
    </div>

  </div>
</template>

<script>
  import $Dialog from "../common/Dialog";

  export default {
    name: 'ResetPwd',
    components: {

    },
    props: [],
    data() {
      return {
        isSendCode:false,
        postData: {
          dest: 90, //表示巴西手机号
          username: "",
          showPass1: false,
          password: "",
          re_password: "",
          smscode: "",
          code: "",
          verify_data: "",
          code_rand: "",
          fp: "dft",
          submit_key: "",
          rkey: null,
          recommend: this.recommendId || "",
          uniqueID: localStorage["phoneId"] || "", //手机唯一标识
        },
      }
    },
    computed: {

    },
    watch: {

    },
    created() {
      this.showPass1 = false;
      // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
      // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
    },
    mounted() {

    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      onSubmit() {
        this.$Model.ResetPwd(this.postData, (data) => {
          var msg = this.$t("common3[0]");
          if (data.code == 0) {
            msg = data.code_dec;
          }
          $Dialog.Toast(msg);
          if (data.code == 1){
            this.$router.replace("/login?username="+this.postData.username);
          }
        });
      },
      getSmsCode() {
        let email = this.postData.username;
        if (!email) {
          this.$Dialog.Toast(this.$t("register.placeholder[0]"));
          return;
        }else if (email.split("@").length==1 || email.split(".").length==1) {
          this.$Dialog.Toast(this.$t("register.placeholder[7]"));
          return;
        }
        this.isSendCode = true;
        this.$Model.GetRegCode(
                {
                  reg_type:0,
                  mail: this.postData.username,
                  code: this.postData.code,
                },
                (data) => {
                  this.isSendCode = false;
                  if (data.code == 0) {
                    $Dialog.Toast(data.code_dec);
                  }
                }
        );
      },
    }
  }
</script>
<style scoped lang="scss">
.text1{
  font-size: 28px;
  font-weight: 600;
  color: #000;
  margin-bottom: 4px;
  margin-top: 60px;
  margin-left: 32px;
}
.text2{
  font-size: 16px;
  color: #000;
  margin-left: 32px;
  width: calc(100% - 70px);
}
  .PageBox {
    color: #333;
    padding: 0;
    background-color: #F5F8FF;
  }

  .van-nav-bar {
    background-color: #F5F8FF;
  }
  .van-nav-bar::v-deep.van-nav-bar__title{
    color:#cccccc;
  }
  .van-nav-bar::v-deep.van-nav-bar__arrow {
    color:#B5B5B5;
  }

  .ScrollBox {
    margin-top: 60px;
    width: 95%;
    margin-left: 10px;
  }

  .van-cell::v-deep.van-cell__title{
    font-size: 14px;
  }

  .van-uploader::v-deep.van-uploader__upload,.van-uploader::v-deep.van-uploader__preview-image,.van-uploader::v-deep.van-uploader__preview{
    margin: 0;
    background-color: #f7f8fa;
    border-radius: 8px;
  }
  .van-cell--borderless.input {
    width: 85%;
    background-color: #fff;
    border-radius: 22.5px;
    overflow: hidden;
    margin-bottom: 25px;
    margin-left: 30px;
    padding: 0 30px 0 13px;
    height: 45px;
  }

  .van-cell--borderless ::v-deep .van-icon {
    font-size: 25px !important;
    color: #ccc !important;
    margin-top: 8px;
  }

  .van-cell--borderless ::v-deep .van-field__right-icon .van-icon-closed-eye {
    font-size: 21px !important;
    color: #555 !important;
    margin-right:-10px;
  }

  .van-field__right-icon ::v-deep van-icon-closed-eye {
    font-size: 18px !important;
    color: #555 !important;
    margin-right:-10px;
  }

  .van-field__right-icon ::v-deep .van-icon-eye{
    font-size: 18px !important;
    color: #555 !important;
    margin-right:-10px;
  }


  .van-cell--borderless ::v-deep .van-field__control {
    color: #000 !important;
    padding-left: 4px;
  }
  .van-cell ::v-deep .van-field__control::-webkit-input-placeholder,
  .van-cell ::v-deep .van-field__control > input::-webkit-input-placeholder {
    color: #777;
    font-size: 16px;
    font-family: Alibaba;
  }

  .van-cell ::v-deep .van-field__control::-moz-placeholder,
  .van-cell ::v-deep .van-field__control > input::-moz-placeholder {
    color: #777;
    font-size: 16px;
    font-family: Alibaba;
  }

  .van-cell ::v-deep .van-field__control:-ms-input-placeholder,
  .van-cell ::v-deep .van-field__control > input::-ms-input-placeholder {
    color: #777;
    font-size: 16px;
    font-family: Alibaba;
  }

  /* .van-cell::v-deep.van-dropdown-menu::-webkit-input-placeholder{
          color: #777;
          font-size: 19px;
          font-family: Alibaba;
      } */
  .van-cell ::v-deep .van-cell__value,
  .van-cell ::v-deep .van-field__body,
  .van-cell ::v-deep .van-field__control,
  .van-cell ::v-deep .van-dropdown-menu,
  .van-cell ::v-deep input {
    height: 100%;
  }

  .btn {
    width: calc(100% - 40px);
    border-radius: 24px;
    line-height: 48px;
    height: 48px;
    background: linear-gradient(135deg, #8AE8F3,#3FC4D5);
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 15px 20px;
  }
</style>
